@import url('https://fonts.googleapis.com/css?family=Merriweather:700|Yantramanav:300,400,500,700&display=swap');
//font-family: 'Yantramanav', sans-serif;
//font-family: 'Merriweather', serif;

$sans: 'Yantramanav', sans-serif;
$serif: 'Merriweather', serif;

$container: 90em;
$large: 60em;
$tablet: 48em;

$red: #BF2B33;
$white: #EAEBE6;


.visuallyhidden { /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
    position: absolute !important;
    height: 1px; width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

/* apply a natural box layout model to all elements, but allowing components to change */
html {
    box-sizing: border-box;

    font-size: 0.8em;

    @media screen and (min-width: $tablet){
        font-size: 1em;
    }
}
*, *:before, *:after {
    box-sizing: inherit;
}

body{
    color: $red;
    font-family: $sans;
}

h1,
h2,
h3,
h4{
    font-family: $serif;
}

p,
button{
    font-family: $sans;
}

p.large{
    font-family: $serif;
    font-size: 2.5em;
}

.button{
    border: none;
    box-shadow: none;
    padding: 0.65em 3.45em;
    padding-bottom: 0.55em;
    text-transform: uppercase;
    letter-spacing: 0.075em;
    background: $red;
    color: $white;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    transform: scale(1);
    font-weight: 500;

    &.light{
        background: $white;
        color: $red;
    }
    
    &:hover,
    &:focus{
        transform: scale(1.05);
    }    

    &.knockout{
        border-color: $white;

        &:hover,
        &:focus{
            background: $white;
            color: $red;
        }
    }

}

.container{
    max-width: $container;
    margin: 0 auto;
    padding: 0 1.5em;
    display: flex;
    
    @media screen and (min-width: $tablet){
        padding: 0 3.5em;
    }
}


.parallax-wrap{
    overflow-y: scroll;
    scroll-behavior: smooth;
    perspective: 2px;
    overflow-x: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;


    section{
        height: 54em;
        position: relative;
        overflow: hidden;

        &::after{
            content: "";
            position: absolute;
            top: -15%;
            right: 0;
            bottom: 0;
            left: 0;
            height: 120%;
            transform: translateZ(-1px) scale(1.5);
            z-index: -1;       
        }
    }
}


#intro{
    height: 40em;
    
    &::after{
        background: url('../../img/hero.png') center center no-repeat;
        background-size: cover;
    }

    @media screen and (min-width: $large){
        height: 55em;
    }

    header{
        position: absolute;
        z-index: 3;
        top: 0;
        width: 100%;
        //background: rgba(255,255,255,0.1);
        padding: 1.5em 0;

        .container{
            justify-content: space-between;
            align-items: center;

            img{
                width: 10em;
                height: auto;

                @media screen and (min-width: $tablet){
                    width: 16em;
                }
            }
        }
    }

    > .container{
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;

        h1{
            font-size: 2.75em;
            color: $white;
            margin: 0;
            max-width: 100%;
            width: 15em;
            //display: none;
        }        
    }
}

#about,
#neighbourhood{
    height: 76em;

    @media screen and (min-width: $tablet){
        height: 50em;
    }
}

.image-copy{
    display: flex;
    flex-wrap: wrap;
    background: url('../../img/section-1-bg.png') center center no-repeat;
    background-size: cover;

    > div{
        height: 50%;
        width: 100%;
        display: flex;
        align-items: center;

        @media screen and (min-width: $tablet){
            width: 50%;
            height: 100%;
        }        
    }

    &--copy{
        background: rgba(191,43,51,0.8);
        
        > div{
            max-width: 100%;
            width: calc((#{$container} - 3.5em) / 2);
            padding: 2.5em;
            color: $white;
        }

        h2{
            font-family: $sans;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin: 0;
        }

        p{
            font-size: 1.35em;
            line-height: 1.35;
            width: 18em;
            max-width: 100%;
        }

        p.large{
            margin: 2rem 0;
            font-size: 2.5em;
        }
    }



    &--image{
        position: relative;
        display: flex;
        justify-content: center;

        .img{
            position: relative;

            width: 100%;
            padding-bottom: 85%;

            background: url('../../img/section-1.png') center center no-repeat;
            background-size: cover;
            margin: 3.5em;

            @media screen and (min-width: $large){
                width: 37em;
                height: 41.25em;
                padding-bottom: 0;
            }
            
        }
    }
}

#neighbourhood{
    background: #EAEBE6;

    .image-copy{

        &--copy{
            background: url('../../img/section-3-bg.png') center center no-repeat;
            background-size: cover;
            justify-content: flex-end;

            > div{
                color: $red;
            }

            p.large{
                max-width: 13em;
            }
        }

        &--image{
            .img{
                background: url('../../img/section-3.png') center center no-repeat;
                background-size: cover;                
            }
        }        
    }
}



#gallery{
    position: relative;
    height: 62vw;

    .slider{
        height: 100%;
        .swiper-container{
            height: 100%;
        }
    }    

    .slider-nav{
        background: rgba(191,43,51,0.8);
        color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        padding: 1em;

        & > .container{
            display: flex;
            justify-content: space-between;
            align-items: center;            
        }

        .slider-nav--buttons{
            display: flex;
        }

        .swiper-button{
            display: block;
            height: 1.5em;
            width: 3em;
            margin: 0 0.5em;
            background: url('../../img/prev.svg') center center no-repeat;
            background-size: contain;
            cursor: pointer;

            &.swiper-button-next{
                background: url('../../img/next.svg') center center no-repeat;
                background-size: contain;                
            }
        }
    }

    .swiper-slide{
        img{
            width: 100%;
            height: auto;
        }

        .caption{
            @extend .visuallyhidden;
        }
    }
}


#more{
    height: 40em;
    background: $red;
    color: $white;
    display: flex;
    flex-direction: column;

    h3{
        font-size: 2.5em;
        max-width: 15em;
        margin: 0;
        margin-bottom: 1em;
    }

    > .container{
        flex-wrap: wrap;
        padding-top: 7em;
        padding-bottom: 7em;
        width: 100%;
        flex: 1;
        align-items: center;

        > div{
            width: 100%;
        }
    }

    #footer{
        align-self: flex-end;
    }
}


#footer{
    width: 100%;
    background: #2C2A2B;
    color: $white;
    padding: 1.5em 0;

    > .container{
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .logos{
        display: flex;
        align-items: center;

        a{

            display: block;
            position: relative;

            img{
                max-width: 100%;
                height: auto;
            }

            &:first-child{
                width: 7.5em;
                height: auto;
                max-width: 100%;
                margin-right: 1.5em;

                &::after{
                    content: "";
                    display: inline-block;
                    height: 2.75em;
                    width: 1px;
                    background: #666;
                    position: absolute;
                    right: -1.5em;
                    top: -0.25em;
                }
            }

            &:last-child{
                width: 7.5em;                
                margin-left: 1.5em;
            }            
        }
    }

    p.copyright{
        font-weight: 300;
        font-size: 0.9em;
        //width: 100%;
    }
}



#register{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
    background: $red;
    color: $white;
    justify-content: center;
    align-items: center;
    display: none;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;

    > .close{
        color: $white;
        position: absolute;
        right: 1rem;
        top: 1rem;
        font-size: 6em;
        font-weight: 100;
        line-height: 0.6;
        cursor: pointer;    
    }

    .inner{
        width: 56em;
        max-width: calc(100% - 2em);
        text-align: center;
        max-height: 90%;
        overflow-y: scroll;
        padding: 0 1em;

        > img{
            max-width: 100%;
            width: 12em;
            margin-bottom: 2.5em;

            @media screen and (min-width: $tablet){
                position: absolute;
                top: 1.5em;
                left: 1.5em;
            }
        }

        > h2{
            font-family: $sans;
            text-transform: uppercase;
            font-size: 1.5em;
            letter-spacing: 0.05em;
            margin: 0;
        }

        > p{
            font-family: $serif;
            font-size: 2.5em;
            width: 12em;
            max-width: 100%;
            margin: 0.5em auto;
        }

        p.copyright{
            font-family: $sans;
            font-size: 0.75em;
            width: auto;
        }
    }

    #thank-you{
        display: none;
    }

    form{
        display: flex;
        flex-wrap: wrap;
        margin: 2.5em 0;
        transition: opacity 0.3s ease-in-out;

        .input{
            width: 100%;
            padding: 1em 0.75em;
            text-align: left;

            @media screen and (min-width: $tablet){
                width: 50%;
            }

            &.wide{
                width: 100%;
            }

            label{
                span{
                    text-transform: uppercase;
                    font-size: 0.8em;
                    display: block;
                }
            }

            input{
                border: none;
                border-bottom: 2px solid $white;
                padding: 0.5em 0;
                background: none;
                display: block;
                width: 100%;
                font-family: $serif;
                font-weight: 300;
                letter-spacing: 0.025em;

                &:focus{
                    box-shadow: none;
                    outline: none;
                }
            }
        }

        .radios{
            width: 100%;
            padding: 1em 0.75em;
            text-align: left;
            
            > span{
                font-family: $serif;
                font-size: 1em;
                margin-right: 2.5em;
            }

            label{
                position: relative;
            }

            input{
                @extend .visuallyhidden;
            }

            input + span{
                padding-left: 2.5em;
                margin-right: 1em;
                text-transform: uppercase;
                font-weight: 300;
                cursor: pointer;                
            }

            input + span::after{
                content: "";
                display: block;
                height: 1.25em;
                width: 1.25em;
                box-shadow: 0 0 0 1px $white;
                position: absolute;
                top: 0;
                left: 0.5em;
                border-radius: 999em;
                border: 0.375em solid $red;
                transition: all 0.15s ease-in-out;
            }

            input:checked + span::after{
                background: $white;
            }
        }

        .dk-select{
            display: block;
            width: 100%;
            line-height: 1.25;

            .dk-selected{
                background: none;
                border: none;
                border-radius: 0px;
                border-bottom: 2px solid $white;
                padding: 0.5em 0;
                font-family: $serif;
                
                &::before{
                    display: none;
                }

                &::after{
                    margin: 0;
                    border: none;
                    width: 3em;
                    padding: 0.25em;
                    padding-right: 0.5em;
                    background: url('../../img/expand.svg') center center no-repeat;
                    background-size: contain;
                    background-origin: content-box;
                }
            }
        }

        .dk-select-options{
            color: $red;
            border-radius: 0px;
            border: none;
            padding: 0;

            li{
                padding: 0.35em;
            }
        }


        .checkbox{
            width: 100%;
            text-align: left;
            margin: 1em 0;

            label{
                position: relative;
            }

            input{
                @extend .visuallyhidden;
            }

            input + span{
                display: block;
                padding-left: 2.5em;
                margin-right: 1em;
                cursor: pointer;                
            }

            input + span::after{
                content: "";
                display: block;
                height: 1.5em;
                width: 1.5em;
                position: absolute;
                top: 0;
                left: 0.5em;
                transition: all 0.15s ease-in-out;
                border: 1px solid $white;
            }

            input:checked + span::after{
                background: url('../../img/check.svg') center center no-repeat;
                background-size: contain;
            }            
        }


        .submit{
            width: 100%;
            padding: 2.5em;
        }

    }




}


